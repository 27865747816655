var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CoolLightBox',{staticClass:"align-items-center",attrs:{"items":_vm.itemImage,"index":_vm.showImage},on:{"close":function($event){_vm.showImage = null}}}),_c('b-modal',{ref:"modal-register-shipment",attrs:{"size":"lg","body-class":"p-0","no-close-on-backdrop":"","id":"modal-1","title":((_vm.reviewing ? 'VIEWING' : 'REGISTER') + " " + (_vm.dataRegister.typeCheck)),"hide-footer":_vm.reviewing},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{on:{"click":function($event){return _vm.$emit('hidden')}}},[_vm._v("CANCEL")])],1),_c('div',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addShipment()}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"SaveIcon"}}),_vm._v("REGISTER")],1)],1)]},proxy:true}])},[_c('div',{staticClass:"m-2"},[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group mb-0 col-md-6"},[_c('ValidationProvider',{attrs:{"rules":"required|max:30","name":"number_of_tracking"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NUMBER OF TRACKING"}},[_c('b-form-input',{staticClass:"form-control text-uppercase",class:errors[0],attrs:{"type":"text","disabled":_vm.reviewing,"maxlength":"30"},model:{value:(_vm.dataShipment.number_of_tracking),callback:function ($$v) {_vm.$set(_vm.dataShipment, "number_of_tracking", $$v)},expression:"dataShipment.number_of_tracking"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"form-group mb-0 col-md-6"},[_c('ValidationProvider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"input-group-1","label":"DATE","label-for":"input-1"}},[_c('b-form-datepicker',{class:errors[0],attrs:{"max":_vm.maxDate,"disabled":_vm.reviewing},model:{value:(_vm.dataShipment.shipping_date),callback:function ($$v) {_vm.$set(_vm.dataShipment, "shipping_date", $$v)},expression:"dataShipment.shipping_date"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Date "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"form-group mb-0 col-md-6"},[_c('ValidationProvider',{attrs:{"rules":"required|max:30","name":"check_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CHECK NUMBER"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('########################'),expression:"'########################'"}],staticClass:"form-control",class:errors[0],attrs:{"type":"text","maxlength":"30","disabled":_vm.reviewing},model:{value:(_vm.dataShipment.check_number),callback:function ($$v) {_vm.$set(_vm.dataShipment, "check_number", $$v)},expression:"dataShipment.check_number"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),(!_vm.dataRegister.isPhysicalCheck)?_c('div',{staticClass:"form-group mb-0",class:_vm.reviewing ? 'col-md-12' : 'col-md-6'},[_c('b-form-group',{attrs:{"label":((_vm.dataRegister.typeCheck) + " COST")}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"disabled":_vm.reviewing},model:{value:(_vm.dataShipment.charge),callback:function ($$v) {_vm.$set(_vm.dataShipment, "charge", $$v)},expression:"dataShipment.charge"}},'money',_vm.money,false))],1)],1):_vm._e(),_c('div',{staticClass:"form-group mb-0 col-md-12"},[_c('b-form-group',{attrs:{"label":_vm.reviewing ? 'EVIDENCE' : 'UPLOAD IMAGE'}},[(!_vm.reviewing)?_c('drag-and-drop',{attrs:{"filesArray":_vm.dataShipment.upload_image},model:{value:(_vm.dataShipment.upload_image),callback:function ($$v) {_vm.$set(_vm.dataShipment, "upload_image", $$v)},expression:"dataShipment.upload_image"}}):_vm._e(),(_vm.reviewing)?_c('div',{staticClass:"d-flex flex-wrap align-items-center"},[(_vm.dataShipment.evidence)?_c('div',{staticClass:"d-flex flex-column align-items-center mr-2"},[_c('a',{staticStyle:{"background-color":"#f8f9fa","box-shadow":"0 0 5px rgba(0, 0, 0, 0.1)","color":"#000","padding":"5px 10px","border-radius":"5px"},style:(_vm.isDarkSkin
                        ? { color: '#fff', background: '#4d5359' }
                        : {}),attrs:{"href":_vm.dataShipment.evidence,"target":"_blank"}},[_vm._v("Download")])]):_c('div',[_vm._v("There is no evidence")])]):_vm._e()],1)],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }