<template>
  <div>
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="filters"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="reload"
      @onChangeCurrentPage="onChangeCurrentPage"
    >
      <template #table>
        <b-table
          slot="table"
          :ref="`refSendingToCreditors${typeTab}`"
          small
          :items="myProvider"
          :fields="tabFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(creditor_name)="data">
            <p class="m-0 p-0">{{ data.item.creditor_name }}</p>
            <p class="m-0 p-0">#: {{ data.item.contact_number }}</p>
          </template>
          <template #cell(mount)="data">
            <p class="m-0 p-0">$ {{ data.item.mount | currency }}</p>
          </template>
          <template #cell(check_type)="data">
            <div class="d-flex justify-content-center">
              <b-badge
                pill
                :variant="isVariantCheck(data.item.check_type)"
                style="padding: 8px; width: 90%"
              >
                <span>{{ data.item.check_type }}</span>
              </b-badge>
            </div>
          </template>
          <template #cell(available_days)="data">
            <div v-if="data.item.available_days < 0">
              <span
                v-b-tooltip.hover.html.right="
                  getTooltip(
                    data.item.available_days,
                    data.item.myssing_days,
                    data.item.shipping_days
                  )
                "
              >
                The sending date has expired
                {{ Math.abs(data.item.available_days) }} ago
              </span>
            </div>
            <div v-else-if="data.item.available_days == 0">
              <span
                v-b-tooltip.hover.html.right="
                  getTooltip(
                    data.item.available_days,
                    data.item.myssing_days,
                    data.item.shipping_days
                  )
                "
              >
                The sending date has expired today
              </span>
            </div>

            <div v-else class="d-flex justify-content-center">
              <feather-icon
                v-b-tooltip.hover.html.right="
                  getTooltip(
                    data.item.available_days,
                    data.item.myssing_days,
                    data.item.shipping_days
                  )
                "
                :icon="
                  hasWarningDiff(data.item.available_days)
                    ? 'AlertCircleIcon'
                    : hasDangerDiff(data.item.available_days)
                    ? 'AlertTriangleIcon'
                    : ''
                "
                size="20"
                class="cursor-pointer mr-1"
                :class="
                  hasWarningDiff(data.item.available_days)
                    ? 'text-warning'
                    : hasDangerDiff(data.item.available_days)
                    ? 'text-danger'
                    : ''
                "
              />
              <span
                :class="
                  hasWarningDiff(data.item.available_days)
                    ? 'text-warning'
                    : hasDangerDiff(data.item.available_days)
                    ? 'text-danger'
                    : `${isDarkSkin ?  'text-white' : 'text-dark'}`
                "
                v-b-tooltip.hover.html.right="
                  getTooltip(
                    data.item.available_days,
                    data.item.myssing_days,
                    data.item.shipping_days
                  )
                "
                >{{ data.item.available_days }}</span
              >
            </div>
          </template>
          <template #cell(tracking)="data">
            <b-button
              variant="outline"
              class="text-center widthClients text-light p-0"
              @click="ModalTracking(data.item)"
            >
              <feather-icon
                icon="ListIcon"
                size="15"
                :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                class="mr-50"
              />
            </b-button>
          </template>
          <template #cell(creditor_address)="data">
            <p class="m-0 p-0">{{ data.item.creditor_city }}</p>
            <p class="m-0 p-0">{{ data.item.creditor_address }}</p>
          </template>
          <template #cell(payment_date)="data">
            <p class="m-0 p-0">{{ data.item.payment_date | myGlobal }}</p>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                v-if="data.item.isPhysicalCheck"
                icon="PrinterIcon"
                class="text-primary mx-1"
                size="20"
                @click="printChecks(data.item)"
                style="cursor: pointer"
              />
              <feather-icon
                v-else
                icon="EyeIcon"
                class="text-info mx-1"
                size="20"
                @click="ModalCheck(data.item)"
                style="cursor: pointer"
              />
              <feather-icon
                v-if="
                  (data.item.has_purchase_order || data.item.isPhysicalCheck) &&
                  typeTab == 1
                "
                icon="EditIcon"
                class="text-warning mx-1"
                size="20"
                @click="ModalRegisterShipment(data.item)"
                style="cursor: pointer"
                v-b-tooltip.hover.right="
                  'Register' + ' ' + data.item.check_type
                "
              />

              <feather-icon
                icon="FileIcon"
                class="text-success mx-1"
                size="20"
                v-if="typeTab == 2"
                @click="openViewRegisterShipment(data.item)"
                style="cursor: pointer"
                v-b-tooltip.hover.right="'View Registered Shipment'"
              />
              <feather-icon
                icon="EditIcon"
                class="text-success mx-1"
                size="20"
                v-if="
                  typeTab == 2 &&
                  data.item.date_file_reception == 0 &&
                  data.item.range_days == 1
                "
                @click="ModalAttachReceiptFile(data.item)"
                style="cursor: pointer"
                v-b-tooltip.hover.right="'Attach receipt file'"
              />
              <feather-icon
                icon="FileIcon"
                class="text-primary mx-1"
                size="20"
                v-if="typeTab == 2 && data.item.date_file_reception != ''"
                @click="openViewFileReception(data.item)"
                style="cursor: pointer"
                v-b-tooltip.hover.right="'View Reception file'"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <print-check-modal
      v-if="statusPrintCheck"
      @hidden="statusPrintCheck = false"
      :dataPrintCheck="dataPrintCheck"
    />
    <ModalViewCheck
      v-if="statusModalViewCheck"
      @hidden="statusModalViewCheck = false"
      :dataView="dataShowView"
    />
    <ModalRegisterShipment
      v-if="statusModalRegisterShipment"
      :dataRegister="dataRegisterShipment"
      :reviewing="reviewingRegisterDocument"
      @hidden="statusModalRegisterShipment = false"
      @closeModalRegisterShipment="closeModalRegisterShipment"
      @refreshGrid="reload"
    />
    <ModalTrackingShipment
      v-if="statusModalTrackingShipment"
      :offerPaymentOrderId="offerPaymentOrderId"
      @hidden="statusModalTrackingShipment = false"
    />
    <ModalFileReception
      v-if="statutsModalFileReception"
      :dataReceptionProp="dataReception"
      :reviewFile="reviewFileReception"
      @hidden="closeFileReception"
      @refreshGrid="updateReceptionFile"
    />
  </div>
</template>

<script>
// Store
import { mapGetters, mapActions } from "vuex";
import store from "@/store";

// Data
import fields from "@/views/correspondence/views/sending-creditors/view/components/data/fields.data";
import filters from "@/views/correspondence/views/sending-creditors/view/components/data/filters.data";
// Services
import SendingToCreditorsService from "@/views/correspondence/views/sending-creditors/service/sending-creditors.service";
import DebtSolutionServices from "@/views/debt-solution/services/debt-solution.service.js";
// Modals
import PrintCheckModal from "@/views/correspondence/views/sending-creditors/view/components/modal/PrintCheckModal";
import ModalViewCheck from "@/views/correspondence/views/sending-creditors/view/components/modal/ModalViewCheck.vue";
import ModalRegisterShipment from "@/views/correspondence/views/sending-creditors/view/components/modal/ModalRegisterShipment.vue";
import ModalFileReception from "@/views/correspondence/views/sending-creditors/view/components/modal/ModalFileReception.vue";
import ModalTrackingShipment from "@/views/correspondence/views/sending-creditors/view/components/modal/ModalTrackingShipment.vue";

export default {
  components: {
    PrintCheckModal,
    ModalViewCheck,
    ModalRegisterShipment,
    ModalTrackingShipment,
    ModalFileReception,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      offerPaymentOrderId: 0,
      totalRows: 0,
      startPage: null,
      endPage: null,
      toPage: null,
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      fields,
      filters,
      dataReception: {
        clientName: "",
        offerPaymentOrderId: "",
      },
      dataShowView: {
        creditor: "",
        mount: "",
        address: "",
        memo: "",
        typeAccount: "",
        accountName: "",
        addresFrom: "",
        typeCheck: "",
        memo_cashier_check_money_order: "",
        client_name: "",
      },
      dataRegisterShipment: {
        typeCheck: "",
        isPhysicalCheck: 0,
        offerPaymentOrderId: "",
        other_payment_id: 0,
        type_check_id: 0,
      },
      // Modal
      statusPrintCheck: false,
      dataPrintCheck: [],
      statusModalViewCheck: false,
      statusModalRegisterShipment: false,
      statusModalTrackingShipment: false,
      reviewingRegisterDocument: false,
      statutsModalFileReception: false,
      reviewFileReception: false,
      items: [],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    typeTab() {
      switch (this.$route.matched[2].name) {
        case "correspondence-sending-creditors-in-proccess":
          return 1; // In Proccess
          break;
        case "correspondence-sending-creditors-sent":
          return 2; // Sent
          break;
        case "correspondence-sending-creditors-not-sent":
          return 3; // Not Sent
          break;
      }
    },
    creditorFilter() {
      return this.filters[4].model;
    },
    tabFields() {
      if (this.typeTab == 1) {
        return this.fields;
      } else return this.fields.filter((item) => item.key != "available_days");
    },
  },
  async created() {
    await this.getUsersAdvisor();
  },
  methods: {
    async reload() {
      await this.updateCounter();
      this.$refs[`refSendingToCreditors${this.typeTab}`].refresh();
    },
    updateReceptionFile(orderId) {
      const order = this.items.find(
        (item) => item.offer_payment_order_id == orderId
      );
      this.$set(order, "date_file_reception", 1);
    },
    async updateCounter() {
      const res = await SendingToCreditorsService.counterSendingToCreditors();
      if (res) {
        const data = res.data;
        const payload = {
          routeName: "correspondence-sending-creditors",
          tag: data > 99 ? "99+" : data,
        };
        store.dispatch(
          "SendingToCreditorsStore/A_SET_COUNTER_SENDING_TO_CREDITORS",
          data > 99 ? "99+" : data
        );
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      }
    },

    hasWarningDiff(available_days) {
      return available_days <= 7 && available_days >= 4;
    },

    hasDangerDiff(available_days) {
      return available_days < 4;
    },

    getTooltip(available_days, missing_days, shipping_days) {
      return `
      <strong>Missing Days: </strong> ${missing_days} <br> 
      <strong>Shipping Days: </strong> ${shipping_days} <br>
      <strong>Available Days: </strong> ${available_days} <br>
      `;
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    async myProvider(ctx) {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          perpage: ctx.perPage,
          page: ctx.currentPage,
          orderby: 9,
          order: "desc",
          from: this.filters[0].model,
          to: this.filters[1].model,
          advisor_id: this.filters[2].model,
          payment_type: this.filters[3].model,
          creditor: this.filters[4].savedValue,
          check_type: this.filters[5].model,
          type_tab: this.typeTab,
        };
        const { status, data } =
          await SendingToCreditorsService.getSendingToCreditors(params);

        if (status == 200) {
          this.items = data.data;
          this.startPage = data.from;
          this.totalRows = data.total;
          this.paginate.currentPage = data.current_page;
          this.paginate.perPage = data.per_page;
          this.endPage = data.last_page;
          this.toPage = data.to;
          return this.items || [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    ModalCheck(params) {
      if (params.address_origin == "" || params.address_origin == null) {
        this.showWarningSwal(
          "The correspondence source address is not defined."
        );
      } else {
        this.statusModalViewCheck = true;
        this.dataShowView.accountName = params.account_name;
        this.dataShowView.address = params.creditor_address;
        this.dataShowView.creditor = params.creditor_name;
        this.dataShowView.memo = params.memo;
        this.dataShowView.mount = params.mount;
        this.dataShowView.typeAccount = params.type_account;
        this.dataShowView.addresFrom = params.address_origin;
        this.dataShowView.typeCheck = params.check_type;
        this.dataShowView.memo_cashier_check_money_order = `Settlement pay to ${params.creditor_name} - ${params.creditor_account} - ${params.client_name}`;
        this.dataShowView.client_name = params.client_name;
      }
    },
    ModalRegisterShipment(params) {
      this.statusModalRegisterShipment = true;
      this.dataRegisterShipment.typeCheck = params.check_type;
      this.dataRegisterShipment.typeCheckNumber = params.check_type_number;
      this.dataRegisterShipment.offerPaymentOrderId =
        params.offer_payment_order_id;
      this.dataRegisterShipment.isPhysicalCheck = params.isPhysicalCheck;
      this.dataRegisterShipment.other_payment_id = params.other_payment_id;
      this.dataRegisterShipment.type_check_id = params.type_check_id;
    },
    ModalAttachReceiptFile(params) {
      this.statutsModalFileReception = true;
      this.reviewFileReception = false;
      this.dataReception.clientName = params.client_name;
      this.dataReception.offerPaymentOrderId = params.offer_payment_order_id;
    },

    openViewFileReception(params) {
      this.statutsModalFileReception = true;
      this.reviewFileReception = params.date_file_reception == 1 ? true : false;
      this.dataReception.clientName = params.client_name;
      this.dataReception.offerPaymentOrderId = params.offer_payment_order_id;
    },

    openViewRegisterShipment(params) {
      this.reviewingRegisterDocument = true;
      this.ModalRegisterShipment(params);
    },
    ModalTracking(params) {
      this.statusModalTrackingShipment = true;
      this.offerPaymentOrderId = params.offer_payment_order_id;
    },
    printChecks(data) {
      this.statusPrintCheck = true;
      this.dataPrintCheck = data;
    },
    closeModalRegisterShipment() {
      this.statusModalRegisterShipment = false;
    },
    closeFileReception() {
      this.statutsModalFileReception = false;
      this.reviewFileReception = false;
    },
    async getUsersAdvisor() {
      // 5 Debt Solution
      try {
        const { data } = await DebtSolutionServices.getAdvisors(5);
        this.filters[2].options = data;
      } catch (error) {
        console.log(error);
      }
    },
    isVariantCheck(typeCheck) {
      if (typeCheck == "CHECK (OVERNIGHT)") {
        return "primary";
      } else if (typeCheck == "MONEY ORDER") {
        return "secondary";
      } else {
        return "dark";
      }
    },
  },

  watch: {
    async creditorFilter() {
      try {
        const data = await DebtSolutionServices.getCreditorsByName(
          this.creditorFilter
        );
        this.filters[4].options = [{ data: [...data.data] }];
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid red;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.circle span {
  display: inline-block;
  text-align: center;
  font-size: 0.5em;
  font-weight: bold;
}
</style>
