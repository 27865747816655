<template>
  <b-modal
    @hidden="$emit('hidden')"
    ref="modal-view-check"
    hide-footer
    size="lg"
    :title="'VIEW' + ' ' + dataView.typeCheck"
    >
    <div class="w-100 mx-auto">
      <b-row>
        <b-col sm="12">
          <b-card
            class="box-shadow-0 mb-0"
            :style="'background-color:transparent !important;'"
          >
            <div
              class="p-4 rounded-0"
              :class="isDarkSkin ? 'dark-theme' : 'ligth-theme'"
            >
              <b-col>
                <b-row class="pl-1 pr-1">
                  <b-col>
                    <h2 class="pb-2 text-center">
                     {{dataView.typeCheck}}
                    </h2>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col
                    class="pb-1"
                    :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                  >
                    <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                      >PAY TO/ PAGAR A:
                    </strong>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col
                    :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                  >
                    <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                      >CREDITOR:
                    </strong>
                    <span
                      :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                      class="text-uppercase"
                      >{{ dataView.creditor }}</span
                    >
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col
                    :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                  >
                    <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                      >AMOUNT:
                    </strong>
                    <span
                      :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                      class="text-uppercase"
                      >$ {{ dataView.mount }}</span
                    >
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col
                    :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                  >
                    <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                      >ADDRESS:
                    </strong>
                    <span
                      :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                      class="text-uppercase"
                      >{{ dataView.address }}</span
                    >
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col
                    class="pr-2"
                    :style="'border-width:2px !important;border-color: #AEB6BF !important ;'"
                  >
                    <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                      >MEMO:
                    </strong>
                    <span
                      :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                      class="text-uppercase"
                      >{{dataView.memo_cashier_check_money_order ||
                      "MEMO GOES HERE" }}</span
                    >
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col>
                    <div
                      class="pb-1 pr-2 border-bottom"
                      :style="'border-width:2px !important;border-color: #AEB6BF !important ;'"
                    ></div>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col
                    class="p-1"
                    :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                  >
                    <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                      >FROM/ DE:
                    </strong>
                  </b-col>
                </b-row>

                <b-row class="pl-1 pr-1">
                  <b-col
                    :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                  >
                    <!-- <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                    >
                    </strong> -->
                  </b-col>
                </b-row>
                <!-- <b-row class="pl-1 pr-1">
                  <b-col
                    :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                  >
                    <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                    >
                      TYPE ACCOUNT:
                    </strong>
                    <span
                      :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                      class="text-uppercase"
                      >{{ dataView.typeAccount }}</span
                    >
                  </b-col>
                </b-row> -->
                <b-row class="pl-1 pr-1">
                  <b-col
                    :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                  >
                    <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                      >CLIENT NAME:
                    </strong>
                    <span
                      :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                      class="text-uppercase"
                      >{{ dataView.client_name }}</span
                    >
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col
                    :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                  >
                    <strong
                      class="mr-1 text-uppercase"
                      :style="'font-size:14px !important;'"
                      >ADDRESS:
                    </strong>
                    <span
                      :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                      class="text-uppercase"
                      >{{ dataView.addresFrom }}</span
                    >
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    dataView: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.toggleModal("modal-view-check");
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@300;500;900&display=swap");
.ligth-theme {
  background: linear-gradient(
    343deg,
    rgba(0, 144, 231, 0.1825105042016807) 8%,
    rgba(255, 255, 255, 0.10127801120448177) 71%
  );
  box-shadow: 0.2px 0.2px 2px 0px #0090e7 !important;
  border-radius: 2px !important;
}
.dark-theme {
  background: linear-gradient(
    343deg,
    rgba(0, 144, 231, 0.1825105042016807) 8%,
    rgba(255, 255, 255, 0.10127801120448177) 71%
  );
  box-shadow: 0.2px 0.2px 2px 0px rgba(255, 255, 255, 0.90127801120448177) !important;
  border-radius: 2px !important;
}
</style>