export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },

  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Negotiator",
    model: null,
    options: [],
    reduce: "id",
    selectText: "value",
    cols: 7,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Payment Type",
    model: null,
    options: [
      {
        value: 0,
        label: "AMG",
      },
      {
        value: 1,
        label: "CLIENT",
      },
    ],
    reduce: "value",
    selectText: "label",
    cols: 5,
    visible: true,
  },
  {
    type: 'suggest',
    margin: true,
    showLabel: true,
    label: 'Creditor',
    model: null,
    placeholder: 'Select',
    savedValue: null,
    showText: 'Select',
    visible: true,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,
    visible: true,

  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Check Type",
    model: null,
    options: [
      {
        value: 2,
        label: "CHECK (OVERNIGHT)",
      },
      {
        value: 3,
        label: "CASHIER CHECK",
      },
      {
        value: 4,
        label: " MONEY ORDER",
      },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true,
  },

]
