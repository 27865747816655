<template>
  <div>
    <b-modal no-close-on-backdrop @hidden="$emit('hidden')" ref="print-check-modal" size="xmd" title="PRINT CHECK"
      hide-footer>
      <div class="d-flex justify-content-between m-2" style="position: relative;">
        <div style="width: 95%;">
          <div class="d-flex justify-content-center">
            <div class="cards" :class="isDarkSkin ? 'dark-theme' : 'ligth-theme'">
              <b-row style="margin-top: 7px;">
                <b-col class="text-right" style="margin-right: 60px;">{{ dataPrintCheck.settlement_date }} </b-col>
              </b-row>
              <b-row class="d-flex justify-content-between mt-2">
                <div style="margin-left: 100px; ">{{ dataPrintCheck.creditor_name }} </div>
                <div style="margin-right: 90px;" class>**$ {{ dataPrintCheck.mount | currency }} </div>

              </b-row>
              <b-row class="mt-1">
                <span style="margin-left: 60px; margin-right: 70px; overflow: hidden; white-space: nowrap;">
                  {{ amountLetters(dataPrintCheck.mount)
                  }}***************************************************************************************************************************</span>
              </b-row>
              <b-row class="d-flex flex-column mt-1" style="line-height: 1;">
                <div style="margin-left: 90px;">{{ dataPrintCheck.creditor_name }} </div>
                <div style="margin-left: 90px;">{{ dataPrintCheck.creditor_address }} </div>
                <div style="margin-left: 90px;">{{ dataPrintCheck.creditor_city }} </div>
              </b-row>
              <b-row style="margin: 50px 0px;">
                <div style="margin-left: 60px;">{{ dataPrintCheck.memo }} </div>
              </b-row>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="cards" :class="isDarkSkin ? 'dark-theme' : 'ligth-theme'">
              <b-row style="margin-top: 7px;" class="font-bold">
                <div style="margin-left: 55px;">{{ dataPrintCheck.settlement_date }} </div>
                <div style="margin-left: 65px;">{{ dataPrintCheck.creditor_name }} </div>
              </b-row>
              <b-row style="margin-top: 7px;" class="d-flex justify-content-between">
                <div style="margin-left: 330px;">{{ dataPrintCheck.memo }} </div>
                <div style="margin-right: 65px;">$ {{ dataPrintCheck.mount | currency }} </div>
              </b-row>
              <b-row class="d-flex justify-content-between" style="margin-top: 200px;">
                <div style="margin-left: 30px;" class="font-bold">{{ dataPrintCheck.bank_name }}
                </div>
                <div>{{ dataPrintCheck.memo }} </div>
                <div style="margin-right: 70px;">$ {{ dataPrintCheck.mount | currency }} </div>
              </b-row>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="cards" :class="isDarkSkin ? 'dark-theme' : 'ligth-theme'">
              <b-row style="margin-top: 7px;" class="font-bold">
                <div style="margin-left: 55px;">{{ dataPrintCheck.settlement_date }} </div>
                <div style="margin-left: 65px;">{{ dataPrintCheck.creditor_name }} </div>
              </b-row>
              <b-row style="margin-top: 7px;" class="d-flex justify-content-between">
                <div style="margin-left: 330px;">{{ dataPrintCheck.memo }} </div>
                <div style="margin-right: 65px;">$ {{ dataPrintCheck.mount | currency }} </div>
              </b-row>
              <b-row class="d-flex justify-content-between" style="margin-top: 200px;">
                <div style="margin-left: 30px;" class="font-bold">{{ dataPrintCheck.bank_name }}
                </div>
                <div>{{ dataPrintCheck.memo }} </div>
                <div style="margin-right: 70px;">$ {{ dataPrintCheck.mount | currency }} </div>
              </b-row>
            </div>
          </div>
        </div>
        <div style="width: 5%;" class="d-flex justify-content-center" v-if="typeTab">
          <feather-icon icon="PrinterIcon" class="text-primary" size="40" @click="printCheck()"
            style="cursor: pointer" />
        </div>
      </div>


    </b-modal>
  </div>
</template>

<script>
// Store
import { mapGetters } from "vuex";
// Services
import SendingToCreditorsService from "@/views/correspondence/views/sending-creditors/service/sending-creditors.service";
// Library
import moment from "moment";
import numbersToLetters from "@/views/debt-solution/views/settlements-made/formats/numbersToLetters";
export default {
  props: {},
  data() {
    return {

      dateOfPrinting: moment().format('MM/DD/YYYY'),
      amountText: "",


    };
  },
  props: {
    dataPrintCheck: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    typeTab() {
      return this.$route.matched[2].name == "correspondence-sending-creditors-in-proccess";  
    },
  },
  mounted() {
    this.toggleModal("print-check-modal");
  },
  async created() {
  },
  methods: {
    amountLetters(mount) {
      this.amountText = numbersToLetters(mount);
      return this.amountText
    },
   async printCheck() {
      let params = {
        dateOfPrinting: this.dataPrintCheck.settlement_date,
        depositor: this.dataPrintCheck.creditor_name,
        amount: this.dataPrintCheck.amount_formatted,
        amountText: this.amountText,
        creditor_address: this.dataPrintCheck.creditor_address,
        memo: this.dataPrintCheck.memo,
        bank_name: this.dataPrintCheck.bank_name,
        creditor_city: this.dataPrintCheck.creditor_city

      }
      console.log(params)
     const result = await SendingToCreditorsService.printChecKPdf(params);
     console.log(result)
      if (result) {
        this.forceFileDownload(result.data, "check.pdf", "blank");
      

      }
    }
  },
};
</script>
<style lang="scss" scoped>
.cards {
  border: 1px solid rgb(112, 112, 255);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  width: 95%;
  height: 280px;
}

.font-bold {
  font-weight: bold;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@300;500;900&display=swap");

.ligth-theme {
  background: linear-gradient(343deg,
      rgba(125, 189, 228, 0.183) 8%,
      rgba(255, 255, 255, 0.10127801120448177) 71%);
  box-shadow: 0.2px 0.2px 2px 0px #0090e7 !important;
  border-radius: 2px !important;
}

.dark-theme {
  background: linear-gradient(343deg,
      rgba(0, 144, 231, 0.1825105042016807) 8%,
      rgba(255, 255, 255, 0.10127801120448177) 71%);
  box-shadow: 0.2px 0.2px 2px 0px rgba(255, 255, 255, 0.90127801120448177) !important;
  border-radius: 2px !important;
}
</style>
