<template>
  <div>
    <CoolLightBox
      class="align-items-center"
      :items="itemFile"
      :index="showFile"
      @close="showFile = null"
    />
    <b-modal
      size="lg"
      body-class="p-0"
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-file-receipt"
      id="modal-1"
      :title="`${reviewFile ? 'VIEWING RECEPTION FILE' : 'REGISTER RECEPTION FILE'}`"
      :hide-footer="reviewFile"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group col-md-6">
              <b-form-group
                id="input-group-1"
                label="CLIENT"
                label-for="input-1"
              >
                <b-form-input
                  v-model="client"
                  type="text"
                  disabled
                  class="form-control"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="form-group col-md-6">
              <ValidationProvider
                name="reception_date"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  id="input-group-1"
                  label="DATE"
                  label-for="input-1"
                >
                  <flat-pickr
                    v-model="reception_date"
                    class="w-100 sm form-control"
                    :config="configFlatPickr"
                    :disabled="reviewFile"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    Date {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="form-group col-md-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group :label="reviewFile ? 'FILE' : 'UPLOAD FILE'">
                  <drag-and-drop
                    v-if="!reviewFile"
                    v-model="upload_file"
                    :filesArray="upload_file"
                  >
                  </drag-and-drop>                  
                  <div
                    v-if="reviewFile"
                    class="d-flex flex-wrap justify-content-center align-items-center"
                  >                   
                    <b-row
                      class="d-flex flex-column align-items-center mr-2"
                      v-if="fileEvidence"
                    >
                <b-skeleton-img v-if="!validationImg" width="200px" height="200px" />
                      <img
                      v-if="validationImg"
                        :src="fileEvidence"
                        alt="image"
                        class="img-fluid cursor-pointer"
                        style="width: 200px; height: 200px"
                        @click="showFile = 0"
                      />
                     
                    </b-row>
                    <!-- <div v-else>There is no file</div> -->
                  </div>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button @click="$emit('hidden')">CANCEL</b-button>
        </div>
        <div>
          <b-button variant="primary" @click="addReceptionFile()"
            ><feather-icon icon="SaveIcon" class="mr-1" />REGISTER</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import dragAndDrop from "@/views/commons/utilities/DragAndDrop";
import SendingToCreditorsService from "@/views/correspondence/views/sending-creditors/service/sending-creditors.service";
import { required } from "vee-validate/dist/rules";
import CoolLightBox from "vue-cool-lightbox";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    dragAndDrop,
    CoolLightBox,
    flatPickr,
  },
  props: {
    dataReceptionProp: {
      type: Object,
    },
    reviewFile: {
      type: Boolean,
    },
  },
    data() {
    return {
      client: "",
      offerPaymentOrderId: "",
      reception_date: "",
      fileEvidence: "",
      upload_file: [],
      validationImg: false,
      itemFile: [],
      showFile: null,
      configFlatPickr: {
        altInput: false,
        altFormat: "F j, Y",
        dateFormat: "m/d/Y",
        locale: "en",
        maxDate: moment().format("MM/DD/YYYY"),
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.showDataReception();
    if (this.reviewFile) {
      this.getReceptionFile();
    }
    this.toggleModal("modal-file-receipt");
  },
  async created(){
    this.validationImg = true;
  },

  methods: {
    showDataReception() {
      this.client = this.dataReceptionProp.clientName;
      this.offerPaymentOrderId = this.dataReceptionProp.offerPaymentOrderId;
    },
    openImage(index) {
      this.showFile = index;
    },
    async addReceptionFile() {
      const result = await this.$refs.observer.validate();
      if (result) {
        try {
          const formData = new FormData();

          formData.append(
            "sent_date",
            moment(this.reception_date).format("YYYY-MM-DD")
          );
          formData.append("file", this.upload_file[0]);
          formData.append("offerPaymentOrderId", this.offerPaymentOrderId);

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SendingToCreditorsService.addReceptionFile(
              formData
            );
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.$emit("hidden");
              this.$emit("refreshGrid", this.offerPaymentOrderId);
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    async getReceptionFile() {
      const params = {
        offer_payment_order_id: this.offerPaymentOrderId,
      };
      const { data } = await SendingToCreditorsService.getReceptionFile(params);
      if (data.length == 0) return;

      this.reception_date = moment(data[0].date_reception).format("MM/DD/YYYY");

      this.fileEvidence = data[0].file_reception;
      if (data[0].evidence) {
        this.itemFile.push({
          src: data[0].file_reception,
          alt: "file reception",
          title: "file reception",
        });
      }
    },
    openImage(index) {
      this.showImage = index;
    },
  },
};
</script>


  