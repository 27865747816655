export default [
  {
    key: "payment_number",
    label: "Payment Code",
  },
  {
    key: "client_name",
    label: "Client",
  },
  {
    key: "creditor_name",
    label: "creditor",
  },
  {
    key: "check_type",
    label: "check type",
    thClass: "text-center",
  },
  {
    key: "negotiator",
    thClass: "text-center",
    tdClass: "text-center",
  },

  {
    key: "mount",
    label: "Amount",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "creditor_address",
    label: "address",
  },
  {
    key: "type_account",
    label: "Account Type",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "payment_date",
    label: "payment date",
  },

  {
    key: "available_days",
    label: "Available Days",
    thClass: "text-center",
    tdClass: "text-center text-danger",
  },
  {
    key: "tracking",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "actions",
    thClass: "text-center",
    tdClass: "text-center ",
  },
];
