<template>
  <b-modal
    hide-footer
    size="lg"
    @hidden="$emit('hidden')"
    ref="modal-tracking-shipment"
    title="TRACKING SHIPMENT"
    body-class="p-0"
    scrollable
  >
    <b-table
      ref="shipmentTable"
      :items="myProvider"
      :fields="fields"
      show-empty
      empty-text="There are no records to show"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(name)="data">
        <b-badge
          pill
          :variant="isVariantStatus(data.item.name)"
          style="padding: 8px; width: 90%"
        >
          <span>{{ data.item.name }}</span>
        </b-badge>
        
      </template>
      <template #cell(created_at)="data">
        <span>{{ data.item.created_at | myGlobalDay }}</span>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
import SendingToCreditorsService from "@/views/correspondence/views/sending-creditors/service/sending-creditors.service";
export default {
  mounted() {
    this.toggleModal("modal-tracking-shipment");
  },
  components: {
    ChangeSms,
    SendingToCreditorsService,
  },
  props: {
    offerPaymentOrderId: {
      type: Number,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "client",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "state",
          tdClass: "text-center",
          thClass: "text-center",
          key: "name",
        },
        {
          key: "created_by",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_at",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      isBusy: false,
    };
  },
  methods: {
    isVariantStatus(status) {
      if (status == "IN PROCCESS") {
        return "warning";
      } else if (status == "SENT") {
        return "success";
      } else {
        return "danger";
      }
    },
    async myProvider() {
      try {
        const id = this.offerPaymentOrderId;
        const { data } =
          await SendingToCreditorsService.getSendingCreditorTracking(id);
        return data || [];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
