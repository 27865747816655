<template>
  <div>
    <CoolLightBox
      class="align-items-center"
      :items="itemImage"
      :index="showImage"
      @close="showImage = null"
    />
    <b-modal
      size="lg"
      body-class="p-0"
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-register-shipment"
      id="modal-1"
      :title="`${reviewing ? 'VIEWING' : 'REGISTER'} ${dataRegister.typeCheck}`"
      :hide-footer="reviewing"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group mb-0 col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:30"
                name="number_of_tracking"
              >
                <b-form-group label="NUMBER OF TRACKING">
                  <b-form-input
                    v-model="dataShipment.number_of_tracking"
                    type="text"
                    :class="errors[0]"
                    :disabled="reviewing"
                    class="form-control text-uppercase"
                    maxlength="30"
                  >
                  </b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="form-group mb-0 col-md-6">
              <ValidationProvider
                name="start_date"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  id="input-group-1"
                  label="DATE"
                  label-for="input-1"
                >
                  <!-- <flat-pickr
                    v-model="dataShipment.shipping_date"
                    class="w-100 sm form-control"
                    :config="configFlatPickr"
                    style="margin-top: 10px"
                    :disabled="reviewing"
                  /> -->
                  <b-form-datepicker
                    v-model="dataShipment.shipping_date"
                    :max="maxDate"
                    :class="errors[0]"
                    :disabled="reviewing"
                  ></b-form-datepicker>
                  <span v-if="errors[0]" class="text-danger">
                    Date {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="form-group mb-0 col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:30"
                name="check_number"
              >
                <b-form-group label="CHECK NUMBER">
                  <b-form-input
                    v-model="dataShipment.check_number"
                    v-mask="'########################'"
                    type="text"
                    :class="errors[0]"
                    class="form-control"
                    maxlength="30"
                    :disabled="reviewing"
                  >
                  </b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </div>

            <div
              class="form-group mb-0"
              v-if="!dataRegister.isPhysicalCheck"
              :class="reviewing ? 'col-md-12' : 'col-md-6'"
            >
              <b-form-group :label="`${dataRegister.typeCheck} COST`">
                <money
                  v-model="dataShipment.charge"
                  v-bind="money"
                  class="form-control"
                  :disabled="reviewing"
                >
                </money>
              </b-form-group>
            </div>

            <div class="form-group mb-0 col-md-12">
              <b-form-group :label="reviewing ? 'EVIDENCE' : 'UPLOAD IMAGE'">
                <drag-and-drop
                  v-if="!reviewing"
                  v-model="dataShipment.upload_image"
                  :filesArray="dataShipment.upload_image"
                ></drag-and-drop>
                <div
                  v-if="reviewing"
                  class="d-flex flex-wrap align-items-center"
                >
                  <div
                    class="d-flex flex-column align-items-center mr-2"
                    v-if="dataShipment.evidence"
                  >
                    <a
                      :href="dataShipment.evidence"
                      target="_blank"
                      style="
                        background-color: #f8f9fa;
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                        color: #000;
                        padding: 5px 10px;
                        border-radius: 5px;
                      "
                      :style="
                        isDarkSkin
                          ? { color: '#fff', background: '#4d5359' }
                          : {}
                      "
                      >Download</a
                    >
                  </div>
                  <div v-else>There is no evidence</div>
                </div>
              </b-form-group>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button @click="$emit('hidden')">CANCEL</b-button>
        </div>
        <div>
          <b-button variant="primary" @click="addShipment()"
            ><feather-icon icon="SaveIcon" class="mr-1" />REGISTER</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import dragAndDrop from "@/views/commons/utilities/DragAndDrop";
import SendingToCreditorsService from "@/views/correspondence/views/sending-creditors/service/sending-creditors.service";
import { Money } from "v-money";
import { required } from "vee-validate/dist/rules";
import CoolLightBox from "vue-cool-lightbox";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    dragAndDrop,
    Money,
    CoolLightBox,
    flatPickr,
  },
  directives: { money: Money },
  props: {
    dataRegister: {
      type: Object,
    },
    reviewing: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    if (this.reviewing) {
      this.getOtherPayment();
    }
    this.toggleModal("modal-register-shipment");
  },
  data() {
    const maxDate = new Date();
    return {
      directives: { money: Money },
      dataShipment: {
        number_of_tracking: "",
        check_number: "",
        shipping_date: "",
        upload_image: [],
        charge: "",
        other_payment_id: 0,
        type_check_id: 0,
        other_payment_id: 0,
        type_check_id: 0,
        evidence: "",
      },
      money: {
        decimal: ",",
        prefix: "$ ",
        thousands: ".",
        precision: 2,
        maxlength: 12,
      },
      itemImage: [],
      showImage: null,
      maxDate: maxDate,
      configFlatPickr: {
        altInput: false,
        altFormat: "F j, Y",
        dateFormat: "m/d/Y",
        locale: "en",
        maxDate: moment().format("MM/DD/YYYY"),
      },
    };
  },
  methods: {
    async getOtherPayment() {
      const params = {
        offer_payment_order_id: this.dataRegister.offerPaymentOrderId,
      };
      const { data } =
        await SendingToCreditorsService.getSendingToCreditorRegisteredPayment(
          params
        );
      if (data.length == 0) return;
      this.dataShipment.number_of_tracking = data[0].operation_number;
      this.dataShipment.number_of_tracking_repeat = data[0].operation_number;
      this.dataShipment.check_number = data[0].check_number;
      this.dataShipment.shipping_date = data[0].operation_date;
      this.dataShipment.charge = data[0].cost;
      this.dataShipment.evidence = data[0].evidence;
      if (data[0].evidence) {
        this.itemImage.push({
          src: data[0].evidence,
          alt: "Evidence",
          title: "Evidence",
        });
      }
    },
    openImage(index) {
      this.showImage = index;
    },
    async addShipment() {
      const result = await this.$refs.observer.validate();
      if (result) {
        try {
          const params = new FormData();
          params.append("check_type", this.dataRegister.type_check_id);
          params.append(
            "offer_payment_order_id",
            this.dataRegister.offerPaymentOrderId
          );
          params.append("payment_number", this.dataShipment.number_of_tracking);
          params.append("check_number", this.dataShipment.check_number);
          params.append(
            "sent_date",
            moment(this.dataShipment.shipping_date).format("YYYY-MM-DD")
          );
          params.append("cost", this.dataShipment.charge);
          params.append("evidence", this.dataShipment.upload_image[0]);
          params.append("created_by", this.currentUser.user_id);
          if (this.dataRegister.other_payment_id) {
            params.append(
              "other_payment_id",
              this.dataRegister.other_payment_id ?? null
            );
          }
          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SendingToCreditorsService.addShipment(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.$emit("hidden");
              this.$emit("refreshGrid");
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
  },
};
</script>
